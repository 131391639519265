export { Event as GEvent } from '@antv/g-canvas';
export { getTheme } from './theme';
export { Node } from './facet/layout/node';
export { Hierarchy } from './facet/layout/hierarchy';
export { buildTableHierarchy } from './facet/layout/build-table-hierarchy';

export * from './common';
export * from './utils';
export * from './cell';
export * from './sheet-type';
export * from './data-set';
export * from './interaction';
export * from './ui/tooltip';
export * from './facet/header';

export const version = '@antv/s2-v1.55.8';
